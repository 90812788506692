import { TextField } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import FileBase64 from 'react-file-base64';
import AuthContext from '../../../services/AuthContext';
import AppButton from '../../common/AppButton';

export default function KnowledgeBaseForm({ data, handleChange, type, handleUpload, getDocument }) {

    const context = useContext(AuthContext)

    return (
        <div className="block">
            <div className="container-fluid">
                <div className="form-row">
                    <div className="form-group col-lg-12">
                        <h4>Knowledge Base</h4>
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-lg-6">
                        <TextField fullWidth label="Name" name="subject" value={data.subject}
                            onChange={(e) => type !== 'view' ? handleChange(e) : false} />
                    </div>
                    <div className="form-group col-lg-6">
                        <TextField fullWidth label="Topic" name="topic" value={data.topic}
                            onChange={(e) => type !== 'view' ? handleChange(e) : false} />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-lg-12">
                        <TextField fullWidth name="problem" label="What is the problem?" value={data.problem}
                            onChange={(e) => type !== 'view' ? handleChange(e) : false} />
                    </div>
                </div>

                <div className="form-row">
                    <div className="form-group col-lg-12">
                        <TextField fullWidth name="body" multiline label="What is the solution?" value={data.body}
                            onChange={(e) => type !== 'view' ? handleChange(e) : false} />
                    </div>
                </div>
                {type !== 'view' && <div><FileBase64 multiple onDone={(e) => handleUpload(e)} /></div>}

                <div className="form-row">

                    {type !== "create" &&
                        data.file_records && data.file_records.length > 0 ?
                        data.file_records.map((item, key) => {
                            return <div className="form-group col-lg-2" key={key}>
                                <p>{item.name}</p>
                                <AppButton onClick={() => getDocument(item.id)} text="view" btnIcon="visibility" style={{ background: context.theme.sidebar.background, color: 'white' }} />
                            </div>
                        })
                        :
                        <p style={{ margin: 0, fontWeight: 'bold' }}>No files have been uploaded.</p>

                    }
                </div>

            </div>

        </div>
    );
}
