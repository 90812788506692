import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import KnowledgeBaseIndex from "./pages/knowledge";
import KnowledgeBaseView from "./pages/knowledge/view";
import KnowledgeBaseEdit from "./pages/knowledge/edit";
import KnowledgeBaseCreate from "./pages/knowledge/create";

export default function Root(props) {

  let context = { ...props }
  let history = useHistory()
  return (
    <AuthContext.Provider
      value={{
        ...props
      }}
    >
      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} />

                    {context.auth.access.knowledge["A"] && context.auth.access.knowledge["R"] && (
                      <Route
                        exact
                        path="/knowledge/index"
                        component={(route) => <KnowledgeBaseIndex route={route} context={context} />}
                      />
                    )}
                    {context.auth.access.knowledge["A"] && context.auth.access.knowledge["R"] && (
                      <Route
                        exact
                        path="/knowledge/view/:id"
                        component={(route) => <KnowledgeBaseView route={route} context={context} />}
                      />
                    )}
                    {context.auth.access.knowledge["A"] && context.auth.access.knowledge["C"] && (
                      <Route
                        exact
                        path="/knowledge/create"
                        component={(route) => <KnowledgeBaseCreate route={route} context={context} />}
                      />
                    )}
                    {context.auth.access.knowledge["A"] && context.auth.access.knowledge["C"] && (
                      <Route
                        exact
                        path="/knowledge/edit/:id"
                        component={(route) => <KnowledgeBaseEdit route={route} context={context} />}
                      />
                    )}
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
