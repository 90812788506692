import React, { useState, useEffect } from "react";
import FilterTable from "../../components/common/FilterTable";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import Loading from "../../components/common/Loading";
import AppButton from "../../components/common/AppButton";
import {
  AppHeaderComponent,
  AppButtonComponent,
  KnowledgeBaseApi,
} from "@unity/components";
import { Link } from "react-router-dom";

export default function KnowledgeBaseIndex({ route, context }) {
  const [data, setData] = useState(false);

  const handleCall = async () => {
    const result = await KnowledgeBaseApi.getKnowledgeIndex();
    setData(result.data);
  };

  useEffect(() => {
    handleCall();
  }, []);

  const columns = [
    { label: "Topic", name: "subject" },
    { label: "Name", name: "topic" },
    { label: "Problem", name: "problem" },
    {
      name: "",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const edit = CanUpdate("knowledge");

          return (
            <div>
              <Link
                to={{
                  pathname: `/knowledge/view/${data[dataIndex].id}`,
                }}
              >
                <AppButton
                  text="view"
                  btnIcon="visibility"
                  style={{
                    background: context.theme.sidebar.background,
                    color: "white",
                  }}
                />
              </Link>
              {edit && (
                <Link
                  to={{
                    pathname: `/knowledge/edit/${data[dataIndex].id}`,
                  }}
                >
                  <AppButton
                    text="edit"
                    btnIcon="edit"
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white",
                      marginLeft: 10,
                    }}
                  />
                </Link>
              )}
            </div>
          );
        },
        filter: false,
      },
    },
  ];

  if (CanDelete("knowledge")) {
    var select = "multiple";
  } else {
    var select = "none";
  }

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    print: true,
    selectableRows: select,
    rowsPerPage: 10,
    page: 0,
    onRowsDelete: (rowsDeleted) => {
      rowsDeleted.data.map(async (item, key) => {});
    },
  };

  if (data) {
    return (
      <>
        <AppHeaderComponent
          context={context}
          addNew={() => (
            <Link to={"/knowledge/create"}>
              <AppButtonComponent
                text="Add New"
                btnIcon="add"
                stylename="primary"
                style={{
                  background: context.theme.top_menu.button_background,
                  color: context.theme.top_menu.button_font,
                }}
              />
            </Link>
          )}
          theme={context.theme}
          name="knowledge"
        />
        <div style={{ paddingTop: 140 }}>
          <FilterTable columns={columns} data={data} options={options} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
