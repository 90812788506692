import React, { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import KnowledgeBaseForm from "../../components/modules/knowledge/knowledgeBaseForm";
import { AppHeaderComponent, KnowledgeBaseApi } from "@unity/components"
export default function KnowledgeBaseView({ route, context }) {
  const [data, setData] = useState(false);

  const handleCall = async () => {
    const result = await KnowledgeBaseApi.getKnowledgeRecord(route.match.params.id);
    setData(result.data);
  };

  const [downloading, setDownloading] = useState(false)

  const getDocument = async (id) => {
    setDownloading(id);
    const res = await KnowledgeBaseApi.getFile(id);
    setDownloading(false);
    var win = window.open(res.data, "_blank");
    win.focus();
  };


  useEffect(() => {
    handleCall();
  }, []);


  if (data) {
    return (
      <>
        <AppHeaderComponent name="knowledge" subpage="view" subpage2={data.su} context={context} theme={context.theme} />
        <div style={{ paddingTop: 120 }}>
          <KnowledgeBaseForm data={data} type="view" getDocument={getDocument} />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
