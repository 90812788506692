import React, { useEffect, useState } from "react";
import Loading from "../../components/common/Loading";
import KnowledgeBaseForm from "../../components/modules/knowledge/knowledgeBaseForm";
import ApiLoader from "../../components/common/ApiLoader";
import { useHistory } from "react-router";
import { AppHeaderComponent, KnowledgeBaseApi } from "@unity/components"
export default function KnowledgeBaseEdit({ route, context }) {
  const [data, setData] = useState(false);
  const [downloading, setDownloading] = useState(false)
  const [loading, setLoading] = useState({ status: false, data: false });
  const [files, setFiles] = useState([])

  let history = useHistory()

  const handleCall = async () => {
    const result = await KnowledgeBaseApi.getKnowledgeRecord(route.match.params.id);
    setData(result.data);
    setFiles(result.data.file_records);
  };

  const getDocument = async (id) => {
    setDownloading(id);
    const res = await KnowledgeBaseApi.getFile(id);
    setDownloading(false);
    var win = window.open(res.data, "_blank");
    win.focus();
  };

  useEffect(() => {
    handleCall();
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleSave = async () => {
    setLoading({ status: true, data: "Saving Records.. Please Wait !" });

    data["file_records"] = files;
    await KnowledgeBaseApi.updateKnowledgeRecord(route.match.params.id, data);

    setLoading({ status: true, data: "Success, Please Wait ...." });

    setTimeout(() => {
      history.push("/knowledge/index");
    }, 4000);

  }

  const handleUpload = (e) => {
    const newData = [...files];
    newData.push(e[0])
    setFiles(newData)
  }


  if (data) {
    return (
      <>
        <ApiLoader status={loading.status} data={loading.data} />
        <AppHeaderComponent saveBtn={handleSave} context={context} theme={context.theme} name="knowledge" subpage="edit" />

        <div style={{ paddingTop: 120 }}>
          <KnowledgeBaseForm data={data} getDocument={getDocument} handleChange={handleChange} handleUpload={handleUpload} type="edit" />
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}
