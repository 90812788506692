import React from "react";
import { Icon } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function AppButton({
  text,
  stylename,
  btnIcon,
  onClick,
  style,
}) {
  if (text && btnIcon) {
    return (
      <Button
        variant="contained"
        color={stylename}
        startIcon={<Icon>{btnIcon}</Icon>}
        onClick={onClick}
        style={style}
      >
        {text}
      </Button>
    );
  } else if (btnIcon) {
    return (
      <Button
        variant="contained"
        color={stylename}
        onClick={onClick}
        style={style}
      >
        <Icon>{btnIcon}</Icon>
      </Button>
    );
  } else if (text) {
    return (
      <Button
        variant="contained"
        color={stylename}
        onClick={onClick}
        style={style}
      >
        {text}
      </Button>
    );
  }
}
